import * as moment from 'moment';
import { ApiResponse, IDynamicFormAllControl, IPrice, ManageMemberFilter, daywiseMeals,dynamicForm,dynamicTable,renewFilter, suggestiondiests, weeklydiets } from '../typings/app.typings';
import { zhCN } from 'date-fns/locale';
import { ICreateWorkout, ISuggestWorkout, IWorkoutWeeklySet } from '../typings/exercise-set.typing';
import { IAsana, ISuggestYoga, IYogaWeeklySet } from '../typings/yoga-set.typing';
import { IActivities, IMinDActivityWeeklySet, ISuggestActivity } from '../typings/mind-set.typing';
import * as saveAs from 'file-saver';
import { CONSTANTS_TEXT } from '../../const/app.constant';
import { allCurrencyName } from '../../const/drop-dwon';
import { RoutePath } from '../../config';
import { StringEditorComponent } from 'survey-angular-ui';

export function getApiParamSet(object: any) {
    var queryParam = "";
    if (object != null) {
        if (typeof (object) === 'string' || typeof (object) === 'number') {
            queryParam = '/' + object;
        } else {
            if (object != undefined) {
                queryParam = "?";
            }
            Object.getOwnPropertyNames(object).forEach((val, index) => {
                if (index > 0) {
                    queryParam += "&"
                }
                queryParam += val + "=" + object[val];
            })
        }
    }
    return queryParam ? queryParam : null;
}


export function errorarray(data:any) {
    var aa = [];
    for (const [key, value] of Object.entries(data)){
        if(Array.isArray(value)){
            if(value.length){
                aa.push(value[0]); 
            }
        }
    }
    return aa
}

// week and day changed date 
export function commonChangeDate(changed:string, type:string){
    let newdate:any;
    if(type == 'Previous-7'){
      newdate = (moment(changed).add(-7,'days')); 
    }else if(type == 'Previous'){
      newdate = moment(changed).add(-1,'days'); 
    }else if(type == 'next'){
      newdate= moment(changed).add(1,'days'); 
    }else if(type == 'next-7'){
      newdate = moment(changed).add(7,'days'); 
    } 
    return newdate._d;
  }

  export function weeklydateset(date:Date,selectedDate:string){
    let datearray:{name:string,id:string,checked:boolean}[] = [];
    for(let i = 0; i < 7; i++){
      let newdate:any = (moment(date).add(i,'days'));
     let id = moment(newdate._d).format('YYYY-MM-DD');
     let name = moment(newdate._d).format('ddd').charAt(0);
     if(id === selectedDate){
      datearray.push({name:name,id:id,checked:true})
     }else{
      datearray.push({name:name,id:id,checked:false})
     }
    }
    return datearray
  }


   // comman all weekly date loop
   export function commanweeklysetdate(changedate:string){
    const alldate = [];
    for(let i =0; i < 7; i++){
     let date:any = (moment(changedate).add(i,'days'));
     alldate.push({date:date._d})
    }
    return alldate
  }

export function commonFormateDate(date:string,formate?:string){
    if(formate){
      return moment(new Date(date)).format(formate).toLocaleString()
    }else{
      return moment(date).format('dd MMM yyyy')
    }
}


// dummy data diet 
export function dummydietAdd(data:any,lenght:number){
    let emptydata = [];
    let dayMeals = [];
    for(let i =0; i < 7; i++){
        dayMeals = [];
        for(let j = 0; j < 8; j++){
            dayMeals.push({foodItems:[],mealIndex:j+1,mealTaken:false})
        }
        let dates:any = (moment(data).add(i,'days'));
        emptydata.push({date:moment(dates._d).format('YYYY-MM-DD'),status:'UNPUBLISHED',meals:dayMeals})
    }
    return emptydata
  // return diet
}

export function dummydietAddSingle(data:any){
  for(let i = 0; i < data.length; i++){
    data[i].meals = [...data[i].meals,... [{foodItems:[],mealIndex:data[i].meals.length + 1,mealTaken:false}]] 
  } 

  return data
}

// Table filter chip function 
export function filterchip(data:IDynamicFormAllControl,newFome:any){
let createNewForm:renewFilter[] = [];
  for(let key in newFome){
      for(let i = 0; i < data.controlForm.length; i++){
          if(key === data.controlForm[i].name){
            if(data.controlForm[i].type === 'select'){
               createNewForm.push({name:data.controlForm[i].name,data:data.controlForm[i].data,type:data.controlForm[i].type,label:data.controlForm[i].label,value:newFome[key],multiple:data.controlForm[i].multiple})
            }else if(data.controlForm[i].type === 'text'){
              createNewForm.push({name:data.controlForm[i].name,type:data.controlForm[i].type,label:data.controlForm[i].label,value:newFome[key]})
            }else if(data.controlForm[i].type === 'radio'){
              var newData:{_id:string, name:string}[] = [];
              if(data?.controlForm[i].categoryValue){
                const da:any=data.controlForm[i].categoryValue;
                for(let j = 0; j < da.length; j++){
                  newData.push({_id:da[j].value,name:da[j].lable})
                }
              }
              createNewForm.push({name:data.controlForm[i].name,data:newData,type:data.controlForm[i].type,label:data.controlForm[i].label,value:newFome[key],multiple:false})
            }
          }
      }
  }

return createNewForm
}


// day wise meals create 
export function dayWiseCreateMeals(data:daywiseMeals[],lenght:number,repeat:boolean = false){
  let create = data;
  for(let i =0; i < lenght; i++){
    if(repeat){
      create.push({
        foodItems:[],
        selectFood:'',
        quantity:'',
        type:'',
        base : '',
        portion_size: '',
        // instructions:[],
        index:create.length + 1,
        repeat:[],
        singleSwap:false,
        singleCopy:false,
      })
    }else{
      create.push({
        foodItems:[],
        selectFood:'',
        quantity:'',
        type:'',
        base : '',
        portion_size: '',
        // instructions:[],
        index:create.length + 1,
      })
    }
  }
return create;
}

// create weekly diets 
export function weeklyDietCreate(data:weeklydiets[],lenght:number,mealslenght:number){
  let create = data;
  for(let i =0; i < lenght; i++){
    let meals = dayWiseCreateMeals([],mealslenght);
    let daywise:any = {
      day:create.length + 1,
      meals:meals
    };
    create.push(daywise);
  }

  return create
}

// create sugestion diet 
export function createsugestiondiet(selecteddate:Date,data:suggestiondiests[],sugestiondietLenght:number,mealslenght:number,importedFrom?:string ,dietID?: number){
  let alldiets:suggestiondiests[] = [];
  let olddata:suggestiondiests[] = data;
  for(let i = 0; i < sugestiondietLenght; i++){
    let object:any = {};
    let meals:suggestiondiests[] = [];
    let dates:any = (moment(selecteddate).add(i,'days'));
    object['date'] = moment(dates._d).format('YYYY-MM-DD')
    object['isPublished'] = false;
    object['meals'] = [...meals];
    object['dayCopy'] = false;
    object['loaderImage'] = false;
    object['daySwap'] = false;
    object['importedFrom'] = importedFrom
    object['dietID'] = dietID
    alldiets.push(object);
  }
  // old data changing 
  for(let i =0; i < alldiets.length; i++){
    if(olddata.length === 0){
      dayWiseCreateMeals(alldiets[i].meals,mealslenght,true);
    }else{
      for(let j = 0; j < olddata.length; j++){
        if(alldiets[i].date === olddata[j].date){
          alldiets[i].isPublished = olddata[j].isPublished;
          alldiets[i].meals = [...olddata[j].meals];
          break;
        }else{
          alldiets[i].meals = dayWiseCreateMeals([],mealslenght,true);
        }
      }
    }
   
  }
  return alldiets;
}

export function dietaddmeasls(data:suggestiondiests[]){
  for(let i =0; i < data.length; i++){
    data[i].meals = dayWiseCreateMeals(data[i].meals,1,true);
  }
  return data
}


export function filterdatapatch(data:IDynamicFormAllControl,newFome:any){
  let getvalue:dynamicForm[] = [];
    for(let key in newFome){
        for(let i = 0; i < data.controlForm.length; i++){
            if(key === data.controlForm[i].name){
              data.controlForm[i].value = newFome[key];
              getvalue.push(JSON.parse(JSON.stringify(data.controlForm[i])));
            }
        }
    }

    data.controlForm.forEach((x:dynamicForm)=>{
      x.value = '';
    })
    
    for(let i = 0; i < getvalue.length; i++){
      for(let j =0; j < data.controlForm.length; j++){
        if(data.controlForm[j].index === getvalue[i].index){
          data.controlForm[j] = getvalue[i]
        }
      }
    }


   let senddata = JSON.parse(JSON.stringify(data));
   return senddata;
}

export function chipfilterform(data:IDynamicFormAllControl,newFome:any){
  let createNewForm:dynamicForm[] = [];
    for(let key in newFome){
        for(let i = 0; i < data.controlForm.length; i++){
            if(key === data.controlForm[i].name){
              data.controlForm[i].value = newFome[key];
              createNewForm.push(data.controlForm[i])
            }
        }
    }
  
   let changed = JSON.parse(JSON.stringify(createNewForm))
   return changed;
  }

export function createWeeklyWorkout(data:IWorkoutWeeklySet[],index:number){
  let weeklyWorkout:IWorkoutWeeklySet[] = data;
  if(weeklyWorkout.length === 0){
    for(let i =0; i < 7; i++){
      let workout:ICreateWorkout[] = JSON.parse(JSON.stringify(createWorkout([],index)));
        let object:IWorkoutWeeklySet ={
        day:i+1,
        workouts:workout
        }
        weeklyWorkout.push(object);
    }
    return weeklyWorkout
  }else{
    for(let i = 0; i < weeklyWorkout.length; i++){
      let workout:ICreateWorkout[] = JSON.parse(JSON.stringify(createWorkout(weeklyWorkout[i].workouts,index)));
      weeklyWorkout[i].workouts = JSON.parse(JSON.stringify([...workout]));
    }
    return weeklyWorkout
  }
}

export function createWorkout(oldWorkout:ICreateWorkout[],index:number){
  let data:ICreateWorkout[] = oldWorkout;
  for(let i =0; i< index;i++){
   let createworkout:ICreateWorkout ={
      index:data.length + 1,
      exercises:[]
    }
    data.push(createworkout);
  }
  return data
}

export function createYoga(yogaListing:IYogaWeeklySet[]){
  let yogaListingData:IYogaWeeklySet[] = yogaListing;
    for(let i =0; i < 7; i++){
        let object:IYogaWeeklySet ={
          day:i+1,
          asana:[]
        }
        yogaListingData.push(object);
    }
    return yogaListingData
}

export function createMindActivity(mindListing:IMinDActivityWeeklySet[]){
  let mindListingData:IMinDActivityWeeklySet[] = mindListing;
    for(let i =0; i < 7; i++){
        let object:IMinDActivityWeeklySet ={
          day:i+1,
          activities:[]
        }
        mindListingData.push(object);
    }
    return mindListingData
}


export function createSuggestionWorkout(selecteddate:Date,data:ISuggestWorkout[],sugestionWorkoutLenght:number,workoutLength:number){
  let allworkout:ISuggestWorkout[] = [];
  let olddata:ISuggestWorkout[] = data;
  for(let i = 0; i < sugestionWorkoutLenght; i++){
    let object:any = {};
    let workout:ICreateWorkout[] = [];
    let dates:any = (moment(selecteddate).add(i,'days'));
    object['date'] = moment(dates._d).format('YYYY-MM-DD')
    object['workouts'] = [...workout];
    object['isPublished'] = false;
    object['dayCopy'] = false;
    object['daySwap'] = false;
    allworkout.push(object);
  }

   // old data changing 
   for(let i =0; i < allworkout.length; i++){
    if(olddata.length === 0){
      daywisecreateWorkout(allworkout[i].workouts,workoutLength);
    }else{
      for(let j = 0; j < olddata.length; j++){
        if(allworkout[i].date === olddata[j].date){
          allworkout[i].isPublished = olddata[j].isPublished;
          allworkout[i].workouts = [...olddata[j].workouts];
          break;
        }else{
          allworkout[i].workouts = daywisecreateWorkout([],workoutLength);
        }
      }
    }
  }
  return allworkout
}

export function daywisecreateWorkout(allworkout:ICreateWorkout[],mealslenght:number){
  let create = allworkout;
  for(let i =0; i < mealslenght; i++){
      create.push({
        exercises:[],
        index:create.length + 1,
        singleSwap:false,
        singleCopy:false,
      })
  }
return create;
} 

// local get Exercise data 
export function localSaveDataGetWorkout(customerId: string, type: string, publish: boolean) {
  let sendData = null;
  if (localStorage.getItem(CONSTANTS_TEXT.saveUserData) !== null) {
    let data: any | null = localStorage.getItem(CONSTANTS_TEXT.saveUserData);
    if (data) {
      let dataWorkout = JSON.parse(data);
      if (dataWorkout[type] && JSON.stringify(dataWorkout[type]) !== '{}') {
        let checkedKey: boolean = false;
        for (let i in dataWorkout.workout) {
          if (i === customerId) {
            checkedKey = true;
            break;
          }
        }
        if (checkedKey) {
          let filteredLocalData: ISuggestWorkout[] | undefined = dataWorkout[type][customerId];
          const  localData= filteredLocalData && filteredLocalData.length && filteredLocalData.filter(item => item !== null);
          sendData = localData && localData.length && localData.map((x: ISuggestWorkout) => {
            if (x.workouts!==null) {
              x.workouts = x.workouts.filter((y: ICreateWorkout) => y.exercises.length);
              return {
                customerId: customerId,
                date: x.date,
                isPublished: publish,
                workouts: x.workouts.map((y1: ICreateWorkout) => ({
                  exercises: y1.exercises,
                  index: y1.index
                }))
              };
            } else {
              return null;
            }
          })
          .filter((y: ISuggestWorkout | null) => {
            // Check if y is not null before further checks
            if (y !== null) {
              const dateIsAfter = moment(moment(new Date()).format('YYYY-MM-DD')).isSameOrBefore(moment(y.date));
              return dateIsAfter;
            }
            return false; // Default to false if y is null
          });
        }
      }
    }
  }
  return sendData;
}


// local get yoga data 
export function localSaveDataGetYoga(customerId:string,type:string,publish:boolean){
  let sendData = null;
  if(localStorage.getItem(CONSTANTS_TEXT.saveUserData) !=null){
    let data:any | null = localStorage.getItem(CONSTANTS_TEXT.saveUserData);
   if(data){
    let dataYoga = JSON.parse(data);
    if(dataYoga[type] && JSON.stringify(dataYoga[type]) !='{}'){
      let checkedKey:boolean = false;
      for(let i in dataYoga[type]){
        if(i === customerId){
          checkedKey = true;
          break
        }
      }
      if(checkedKey){
       let filteredLocalData:ISuggestYoga[] | undefined= dataYoga[type][customerId]; 
       const  localData= filteredLocalData && filteredLocalData.length && filteredLocalData.filter(item => item !== null);
       sendData = localData && localData.length && localData.map((x:ISuggestYoga)=>{
        return {customerId:customerId,date:x.date,isPublished:publish,asana:x.asana } }).filter((y:ISuggestYoga)=>{
          const dateIsAfter = moment(moment(new Date()).format('YYYY-MM-DD')).isSameOrBefore(moment(y.date))
          return dateIsAfter
        })
      }
    }
   }
  }
  return sendData
}

// local get Activity data 
export function localSaveDataGetActivity(customerId:string,type:string,publish:boolean){
  let sendData = null;
  if(localStorage.getItem(CONSTANTS_TEXT.saveUserData) !=null){
    let data:any | null = localStorage.getItem(CONSTANTS_TEXT.saveUserData);
   if(data){
    let dataActivity = JSON.parse(data);
    if(dataActivity[type] && JSON.stringify(dataActivity[type]) !='{}'){
      let checkedKey:boolean = false;
      for(let i in dataActivity[type]){
        if(i === customerId){
          checkedKey = true;
          break
        }
      }
      if(checkedKey){
       let filteredLocalData:ISuggestActivity[] | undefined= dataActivity[type][customerId]; 
       const  localData= filteredLocalData && filteredLocalData.length && filteredLocalData.filter(item => item !== null);
       sendData = localData && localData.length && localData.map((x:ISuggestActivity)=>{
        return {customerId:customerId,userId:customerId,date:x.date,isPublished:publish,activities:x.activities } }).filter((y:ISuggestActivity)=>{
          const dateIsAfter = moment(moment(new Date()).format('YYYY-MM-DD')).isSameOrBefore(moment(y.date))
          return dateIsAfter
        })
      }
    }
   }
  }
  return sendData
}

// create Activity user
export function createSuggestionYoga(selecteddate:Date,data:ISuggestYoga[],sugestionWorkoutLenght:number,workoutLength:number){
  let allYoga:ISuggestYoga[] = [];
  let olddata:ISuggestYoga[] = data;
  for(let i = 0; i < sugestionWorkoutLenght; i++){
    let object:any = {};
    let asana:IAsana[] = [];
    let dates:any = (moment(selecteddate).add(i,'days'));
    object['date'] = moment(dates._d).format('YYYY-MM-DD')
    object['asana'] = [...asana];
    object['isPublished'] = false;
    object['dayCopy'] = false;
    object['daySwap'] = false;
    allYoga.push(object);
  }
  return allYoga
}

// create Activity user
export function createSuggestionActivity(selecteddate:Date,data:ISuggestActivity[],sugestionActivityLenght:number){
  let allActivity:ISuggestActivity[] = [];
  for(let i = 0; i < sugestionActivityLenght; i++){
    let object:any = {};
    let activities:IActivities[] = [];
    let dates:any = (moment(selecteddate).add(i,'days'));
    object['date'] = moment(dates._d).format('YYYY-MM-DD')
    object['activities'] = [...activities];
    object['isPublished'] = false;
    object['dayCopy'] = false;
    object['daySwap'] = false;
    allActivity.push(object);
  }
  return allActivity
}


//remove keys that are empty
export function removeEmptyKeys(obj:any) {
  for (let key in obj) {
    if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      removeEmptyKeys(obj[key]);
      if (Array.isArray(obj[key])) {
        obj[key].forEach((item: { [key: string]: any }) => {
          if (typeof item === "object") {
            removeEmptyKeys(item);
          }
        });
      }
    }
  }
  return obj
}
// Remove key with empty string value
export function removeEmptyStrings(obj: any) {
  for (let key in obj) {
    if (obj[key] === "" || obj[key] === null) {
      delete obj[key]; 
    }
  }
  return obj;
}
// remove empty array key
export function removeEmptyArrays(data:any){
  for (const key in data){
    if (Array.isArray(data[key]) && data[key].length === 0) {
      delete data[key];
    }else if (typeof data[key] === 'object') {
      removeEmptyArrays(data[key]);
    }
  }
  return data
}

//function for downloading file 
export function downloadFiles(downloadUrl: string | Blob, fileName?: string | undefined) {
  saveAs(downloadUrl, fileName);
}

// Common function for search with code, phone number and name
export function searchWithCodeNumberName(event:string){
  let filterObject:ManageMemberFilter={}
  if(checkPattern(event,/^#(\d+)$/)){
    if(event.replace('#', '')){
      filterObject.code=Number(event.replace('#', ''));
    }
  }else if(checkPattern(event,/^(\+?[0-9]+)$/)){
    filterObject.phoneNumber=event;
  }else{
    filterObject.name=event;
  }
  return filterObject
}

// function to check the string with pattern
export function checkPattern(event:string,pattern:RegExp){
  if (pattern.test(event)){
    return true
  }else{
    return false
  }
}

// pick first alphabet of each word of name
export function pickFirstAlphabetOfEachWord(name:string){
  const words=name.split(" ")
  const initials=words.map(word=>word.charAt(0))
  return initials.join('');
}

export function dublicateStringRemove(list:string[]){
  return [...new Set(list)]
}


export function Urldownload(url:string,id:string){
  fetch(url)
  .then((response) => response.blob())
  .then((blob) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${id}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}

export function formatPhoneNumber(countryCode: string | undefined, phoneNumber: string | undefined): string {
  if (countryCode && phoneNumber) {
    if (phoneNumber.startsWith(`${countryCode}`)) {
      return phoneNumber;
    } else {
      return `${countryCode}${phoneNumber}`;
    }
  } else if (phoneNumber) {
    return phoneNumber;
  } else {
    return 'N/A';
  }
}

// update data dynmic form 
export function dynamicFormDataUpdate(form:IDynamicFormAllControl,ValueForm:any){
  for(let i in ValueForm){
    form.controlForm.forEach((x:dynamicForm)=>{
      if(i === x.name){
        x.value = ValueForm[i];
      }
    })
  }
  return form
}



// export function createAllCountryPrices(prices:IPrice[],displayName?:string){
//   let selectedprices = prices.find((x:IPrice) => x.currency.name === 'INR');
//   let checkedList:{name:string,checked:boolean,icon:string}[] = allCurrencyName.map((x:{name:string,checked:boolean,icon:string})=>{
//     let checkedprice =  prices.find((p:IPrice)=> p.currency.name === x.name);
//     if(checkedprice){
//       x.checked = true;
//     }else{
//       x.checked = false;
//     }
//     return x
//     }).filter((x:{checked:boolean})=> !x.checked)

//   if(selectedprices){
//     checkedList.forEach((x:{name:string,checked:boolean,icon:string})=>{
//           let createPrice = {} as IPrice;
//           if(x.name === 'AUD'){
//            if(selectedprices && selectedprices.originalPrice){
//              createPrice['originalPrice'] =  Math.round((((selectedprices.originalPrice + (0.1 * selectedprices.originalPrice)) / 53.4) / 5) * 5 )
//            }
//            if(selectedprices && selectedprices.sellingPrice){
//              createPrice['sellingPrice'] =  Math.round((((selectedprices.sellingPrice + (0.1 * selectedprices.sellingPrice)) / 53.4) / 5) * 5 )
//            }
//            if(selectedprices && selectedprices.variablePrice){
//              createPrice['variablePrice'] =  Math.round((((selectedprices.variablePrice + (0.1 * selectedprices.variablePrice)) / 53.4) / 5) * 5 )
//            }else{
//              createPrice['variablePrice'] = 0;
//            }
//            createPrice['currency'] = x;
//            prices.push(createPrice);

//           }else if(x.name === 'USD'){
//            if(selectedprices && selectedprices.originalPrice){
//              createPrice['originalPrice'] =  Math.round((((selectedprices.originalPrice + (0.1 * selectedprices.originalPrice)) / 83) / 5) * 5 )
//            }
//            if(selectedprices && selectedprices.sellingPrice){
//              createPrice['sellingPrice'] =  Math.round((((selectedprices.sellingPrice + (0.1 * selectedprices.sellingPrice)) / 83) / 5) * 5 )
//            }
//            if(selectedprices && selectedprices.variablePrice){
//              createPrice['variablePrice'] =  Math.round((((selectedprices.variablePrice + (0.1 * selectedprices.variablePrice)) / 83) / 5) * 5 )
//            }else{
//              createPrice['variablePrice'] = 0;
//            }
//            createPrice['currency'] = x;
//            prices.push(createPrice);

//           }else if(x.name === 'GBP'){
//            if(selectedprices && selectedprices.originalPrice){
//              createPrice['originalPrice'] =  Math.round((((selectedprices.originalPrice + (0.1 * selectedprices.originalPrice)) / 103) / 5) * 5 )
//            }
//            if(selectedprices && selectedprices.sellingPrice){
//              createPrice['sellingPrice'] =  Math.round((((selectedprices.sellingPrice + (0.1 * selectedprices.sellingPrice)) / 103) / 5) * 5 )
//            }
//            if(selectedprices && selectedprices.variablePrice){
//              createPrice['variablePrice'] =  Math.round((((selectedprices.variablePrice + (0.1 * selectedprices.variablePrice)) / 103) / 5) * 5 )
//            }else{
//              createPrice['variablePrice'] = 0;
//            }
//            createPrice['currency'] = x;
//            prices.push(createPrice);
           
//           }else if(x.name === 'CAD'){
//            if(selectedprices && selectedprices.originalPrice){
//              createPrice['originalPrice'] =  Math.round((((selectedprices.originalPrice + (0.1 * selectedprices.originalPrice)) / 60.22) / 5) * 5 )
//            }
//            if(selectedprices && selectedprices.sellingPrice){
//              createPrice['sellingPrice'] =  Math.round((((selectedprices.sellingPrice + (0.1 * selectedprices.sellingPrice)) / 60.22) / 5) * 5 )
//            }
//            if(selectedprices && selectedprices.variablePrice){
//              createPrice['variablePrice'] =  Math.round((((selectedprices.variablePrice + (0.1 * selectedprices.variablePrice)) / 60.22) / 5) * 5 )
//            }else{
//              createPrice['variablePrice'] = 0;
//            }
//            createPrice['currency'] = x;
//            prices.push(createPrice);
           
           
//           }else if(x.name === 'EUR'){

//            if(selectedprices && selectedprices.originalPrice){
//              createPrice['originalPrice'] =  Math.round((((selectedprices.originalPrice + (0.1 * selectedprices.originalPrice)) / 88.5) / 5) * 5 )
//            }
//            if(selectedprices && selectedprices.sellingPrice){
//              createPrice['sellingPrice'] =  Math.round((((selectedprices.sellingPrice + (0.1 * selectedprices.sellingPrice)) / 88.5) / 5) * 5 )
//            }
//            if(selectedprices && selectedprices.variablePrice){
//              createPrice['variablePrice'] =  Math.round((((selectedprices.variablePrice + (0.1 * selectedprices.variablePrice)) / 88.5) / 5) * 5 )
//            }else{
//              createPrice['variablePrice'] = 0;
//            }
//            createPrice['currency'] = x;
//            prices.push(createPrice);
           
//           }else if(x.name === 'AED'){
//            if(selectedprices && selectedprices.originalPrice){
//              createPrice['originalPrice'] =  Math.round((((selectedprices.originalPrice + (0.1 * selectedprices.originalPrice)) / 22.6) / 5) * 5 )
//            }
//            if(selectedprices && selectedprices.sellingPrice){
//              createPrice['sellingPrice'] =  Math.round((((selectedprices.sellingPrice + (0.1 * selectedprices.sellingPrice)) / 22.6) / 5) * 5 )
//            }
//            if(selectedprices && selectedprices.variablePrice){
//              createPrice['variablePrice'] =  Math.round((((selectedprices.variablePrice + (0.1 * selectedprices.variablePrice)) / 22.6) / 5) * 5 )
//            }else{
//              createPrice['variablePrice'] = 0;
//            }
//            createPrice['currency'] = x;
//            prices.push(createPrice);
//           }
//     })

//     return prices
//   }else{
//     return prices
//   }
// }
export function createAllCountryPrices(prices: IPrice[], displayName?: string) {
  let selectedPrices = prices.find((x: IPrice) => x.currency.name === 'INR');
  if (!selectedPrices) return prices;
  let checkedList: { name: string; checked: boolean; icon: string }[] = allCurrencyName
    .map((x: { name: string; checked: boolean; icon: string }) => {
      let checkedPrice = prices.find((p: IPrice) => p.currency.name === x.name);
      x.checked = !!checkedPrice;
      return x;
    })
    .filter((x) => !x.checked);
  if (selectedPrices) {
    checkedList.forEach((currency) => {
      let createPrice = {} as IPrice;
      const rate = {
        AUD: 53.4,
        USD: 83,
        GBP: 103,
        CAD: 60.22,
        EUR: 88.5,
        AED: 22.6,
      }[currency.name];

      if (rate) {
        if (selectedPrices && selectedPrices.originalPrice) {
          createPrice.originalPrice = displayName === "Clinical Dietitian"
            ? ((selectedPrices.originalPrice + selectedPrices.originalPrice * 0.1) / rate)
            : Math.round((((selectedPrices.originalPrice + selectedPrices.originalPrice * 0.1) / rate) / 5) * 5);
        }
        
        if (selectedPrices && selectedPrices!.sellingPrice) {
          createPrice.sellingPrice = displayName === "Clinical Dietitian"
            ? ((selectedPrices.sellingPrice + selectedPrices.sellingPrice * 0.1) / rate)
            : Math.round((((selectedPrices.sellingPrice + selectedPrices.sellingPrice * 0.1) / rate) / 5) * 5);
        }
        if(selectedPrices && selectedPrices.variablePrice){
          createPrice.variablePrice = displayName === "Clinical Dietitian"
          ? ((selectedPrices.variablePrice + selectedPrices.variablePrice * 0.1) / rate)
          : Math.round((((selectedPrices.variablePrice + selectedPrices.variablePrice * 0.1) / rate) / 5) * 5)
        }else{
          createPrice.variablePrice=0
        }
        createPrice.currency = currency;
        prices.push(createPrice);
      }
    });

    return prices;
  } else {
    return prices;
  }
}


export function orderCurrencyCal(prices:IPrice[],curency:{name:string,icon:string} | undefined){
  if(curency){
    let checkCurency = prices.find((x:IPrice)=> x.currency.name === curency.name);
    if(checkCurency){
      return prices
    }else{
      let selectedprices = prices.find((x:IPrice) => x.currency.name === 'INR');
      if(selectedprices){
        let createPrice = {} as IPrice;
        if(curency.name === 'AUD'){
         if(selectedprices && selectedprices.originalPrice){
           createPrice['originalPrice'] =  Math.round((((selectedprices.originalPrice + (0.1 * selectedprices.originalPrice)) / 53.4) / 5) * 5 )
         }
         if(selectedprices && selectedprices.sellingPrice){
           createPrice['sellingPrice'] =  Math.round((((selectedprices.sellingPrice + (0.1 * selectedprices.sellingPrice)) / 53.4) / 5) * 5 )
         }
         if(selectedprices && selectedprices.variablePrice){
           createPrice['variablePrice'] =  Math.round((((selectedprices.variablePrice + (0.1 * selectedprices.variablePrice)) / 53.4) / 5) * 5 )
         }else{
           createPrice['variablePrice'] = 0;
         }
         createPrice['currency'] = curency;
         prices.push(createPrice);

        }else if(curency.name === 'USD'){

         if(selectedprices && selectedprices.originalPrice){
           createPrice['originalPrice'] =  Math.round((((selectedprices.originalPrice + (0.1 * selectedprices.originalPrice)) / 83) / 5) * 5 )
         }
         if(selectedprices && selectedprices.sellingPrice){
           createPrice['sellingPrice'] =  Math.round((((selectedprices.sellingPrice + (0.1 * selectedprices.sellingPrice)) / 83) / 5) * 5 )
         }
         if(selectedprices && selectedprices.variablePrice){
           createPrice['variablePrice'] =  Math.round((((selectedprices.variablePrice + (0.1 * selectedprices.variablePrice)) / 83) / 5) * 5 )
         }else{
           createPrice['variablePrice'] = 0;
         }
         createPrice['currency'] = curency;
         prices.push(createPrice);

        }else if(curency.name === 'GBP'){
         if(selectedprices && selectedprices.originalPrice){
           createPrice['originalPrice'] =  Math.round((((selectedprices.originalPrice + (0.1 * selectedprices.originalPrice)) / 103) / 5) * 5 )
         }
         if(selectedprices && selectedprices.sellingPrice){
           createPrice['sellingPrice'] =  Math.round((((selectedprices.sellingPrice + (0.1 * selectedprices.sellingPrice)) / 103) / 5) * 5 )
         }
         if(selectedprices && selectedprices.variablePrice){
           createPrice['variablePrice'] =  Math.round((((selectedprices.variablePrice + (0.1 * selectedprices.variablePrice)) / 103) / 5) * 5 )
         }else{
           createPrice['variablePrice'] = 0;
         }
         createPrice['currency'] = curency;
         prices.push(createPrice);
         
        }else if(curency.name === 'CAD'){
         if(selectedprices && selectedprices.originalPrice){
           createPrice['originalPrice'] =  Math.round((((selectedprices.originalPrice + (0.1 * selectedprices.originalPrice)) / 60.22) / 5) * 5 )
         }
         if(selectedprices && selectedprices.sellingPrice){
           createPrice['sellingPrice'] =  Math.round((((selectedprices.sellingPrice + (0.1 * selectedprices.sellingPrice)) / 60.22) / 5) * 5 )
         }
         if(selectedprices && selectedprices.variablePrice){
           createPrice['variablePrice'] =  Math.round((((selectedprices.variablePrice + (0.1 * selectedprices.variablePrice)) / 60.22) / 5) * 5 )
         }else{
           createPrice['variablePrice'] = 0;
         }
         createPrice['currency'] = curency;
         prices.push(createPrice);
         
         
        }else if(curency.name === 'EUR'){

         if(selectedprices && selectedprices.originalPrice){
           createPrice['originalPrice'] =  Math.round((((selectedprices.originalPrice + (0.1 * selectedprices.originalPrice)) / 88.5) / 5) * 5 )
         }
         if(selectedprices && selectedprices.sellingPrice){
           createPrice['sellingPrice'] =  Math.round((((selectedprices.sellingPrice + (0.1 * selectedprices.sellingPrice)) / 88.5) / 5) * 5 )
         }
         if(selectedprices && selectedprices.variablePrice){
           createPrice['variablePrice'] =  Math.round((((selectedprices.variablePrice + (0.1 * selectedprices.variablePrice)) / 88.5) / 5) * 5 )
         }else{
           createPrice['variablePrice'] = 0;
         }
         createPrice['currency'] = curency;
         prices.push(createPrice);
         
        }else if(curency.name === 'AED'){
         if(selectedprices && selectedprices.originalPrice){
           createPrice['originalPrice'] =  Math.round((((selectedprices.originalPrice + (0.1 * selectedprices.originalPrice)) / 22.6) / 5) * 5 )
         }
         if(selectedprices && selectedprices.sellingPrice){
           createPrice['sellingPrice'] =  Math.round((((selectedprices.sellingPrice + (0.1 * selectedprices.sellingPrice)) / 22.6) / 5) * 5 )
         }
         if(selectedprices && selectedprices.variablePrice){
           createPrice['variablePrice'] =  Math.round((((selectedprices.variablePrice + (0.1 * selectedprices.variablePrice)) / 22.6) / 5) * 5 )
         }else{
           createPrice['variablePrice'] = 0;
         }
         createPrice['currency'] = curency;
         prices.push(createPrice);
        }
    
      return prices
      }else{
        return prices
      }
    }
  }else{
    return prices
  }
}

//for page redirection to profile
 export function redirectPage(id:string|undefined, route:string|undefined){
  if(id){
    const url=RoutePath.manage_users+'/'+RoutePath.manage_members+'/'+route+'/'+id
    window.open(url, '_blank');
  }
 }


export function dynamicTableKeyPermision(table:dynamicTable,heading:string,body:string){
let headingIndex =  table.tableHeading.findIndex((x:string) => heading === x);
let bodyIndex =  table.tableBody.findIndex((x:string) => body === x);
  if(headingIndex !=-1 && bodyIndex !=-1){
    table.tableHeading.splice(headingIndex,1)
    table.tableBody.splice(bodyIndex,1)
    return table
  }else{
    return  table
  }
}


export function dummyResponseAdd(data:any,dummyResponse:ApiResponse){
 return dummyResponse.data = data;
}


