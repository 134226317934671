import { Routes } from '@angular/router';
import { RoutePath } from '../router.config';
import { AuthGuard } from '../../guards/auth.guard';

export const MANAGE_LAYOUT_ROUTE: Routes = [
  {
    path: RoutePath.roster,
    canActivate: [AuthGuard],
    data:{ pageId: ['roster'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/manage-users/roster/roster.module').then((m) => m.RosterModule),
  },
  {
    path: RoutePath.bulk_client_shifting,
    canActivate: [AuthGuard],
    data:{ pageId: ['bulk-client-shifting'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/manage-users/bulk-client-shifting/bulk-client-shifting.module').then((m) => m.BulkClientShiftingModule),
  },
  {
    path: RoutePath.manage_members,
    canActivate: [AuthGuard],
    data:{ pageId: ['manage-members'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/manage-users/manage-members/manage-members.module').then((m) => m.ManageMembersModule),
  },
  {
    path: RoutePath.referral,
    canActivate: [AuthGuard],
    data:{ pageId: ['referral'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/manage-users/referral/referral.module').then((m) => m.ReferralModule),
  },
  {
    path: RoutePath.chats,
    canActivate: [AuthGuard],
    data:{ pageId: ['chats'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/manage-users/chats/chats.module').then((m) => m.ChatsModule),
  },
  {
    path: RoutePath.calls_logs,
    canActivate: [AuthGuard],
    data:{ pageId: ['calls-logs'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/manage-users/calls-logs/calls-logs.module').then((m) => m.CallsLogsModule),
  },
  {
    path: RoutePath.wellness,
    canActivate: [AuthGuard],
    data:{ pageId: ['wellness'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/manage-users/wellness/wellness.module').then((m) => m.WellnessModule),
  },
  {
    path: RoutePath.wellness_coaches,
    canActivate: [AuthGuard],
    data:{ pageId: ['wellness-coaches'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/manage-users/wellness-coaches/wellness-coaches.module').then((m) => m.WellnessCoachesModule),
  },
  {
    path: RoutePath.lab_test,
    canActivate: [AuthGuard],
    data:{ pageId: ['lab-test'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/manage-users/lab-tests/lab-tests.module').then((m) => m.LabTestsModule),
  },

  // Manage Members Routing
  {
    path: RoutePath.manage_members,
    canActivate: [AuthGuard],
    data:{ pageId: ['manage-members'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/manage-users/manage-members/members-layout/members-layout.module').then((m) => m.MembersLayoutModule),
  },

]

