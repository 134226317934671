import { Routes } from '@angular/router';
import { RoutePath } from '../router.config';
import { AuthGuard } from '../../guards/auth.guard';

export const MEDUSA_LAYOUT_ROUTE: Routes = [
    {
        path: RoutePath.medusa_orders,
        canActivate: [AuthGuard],
        data:{ pageId: ['orders'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/medusa/orders/orders.module').then((m) => m.OrdersModule),
    },
    {
        path: RoutePath.medusa_products,
        canActivate: [AuthGuard],
        data:{ pageId: ['products'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/medusa/products/products.module').then((m) => m.ProductsModule),
    },
    {
        path: RoutePath.medusa_collections,
        canActivate: [AuthGuard],
        data:{ pageId: ['collections'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/medusa/collections/collections.module').then((m) => m.CollectionsModule),
    },
    {
        path: RoutePath.medusa_categories,
        canActivate: [AuthGuard],
        data:{ pageId: ['categories'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/medusa/categories/categories.module').then((m) => m.CategoriesModule),
    },
    {
        path: RoutePath.medusa_discounts,
        canActivate: [AuthGuard],
        data:{ pageId: ['discounts'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/medusa/discounts/discounts.module').then((m) => m.DiscountsModule),
    },
    {
        path: RoutePath.medusa_banners,
        canActivate: [AuthGuard],
        data:{ pageId: ['banners'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/medusa/medusa-banner/medusa-banner.module').then((m) => m.MedusaBannerModule),
    },
    {
        path: RoutePath.medusa_analytics,
        canActivate: [AuthGuard],
        data:{ pageId: ['analytics'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/medusa/analytics/analytics.module').then((m) => m.AnalyticsModule),
    },
    {
        path: RoutePath.medusa_vendors,
        canActivate: [AuthGuard],
        data:{ pageId: ['vendors'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/medusa/vendors/vendors.module').then((m) => m.VendorsModule),
    }
]