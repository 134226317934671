<header class="header bg-cyan-blue z-index-5" #dynamicHeight>
	<div class="navbar navbar-expand-md py-md-0 py-2">
		<div class="container-fluid">
			<!-- Logo -->
			<div class="logo-container ps-lg-3 pe-lg-4 pe-3">
				<a class="d-block">
					<img src="/assets/images/logos/logo-white.svg" class="logo d-none d-lg-block" alt="logo">
					<img src="/assets/images/favicon/32X32-favicon.png" width="24" class="favicon d-lg-none" alt="favicon">
				</a>
			</div>
			<!-- Main Menus -->
			<div class="collapse navbar-collapse menus" id="navbarContent">
				<ul class="navbar-nav position-relative me-auto ps-3 pb-md-0 pb-2">
					<li class="nav-item" [ngClass]="{'dropdown':item.subMenu.length}" *ngFor="let item of sidebarMenu">
						<ng-container *ngIf="item.permission && item.title !='Navbar'">
							<a [routerLink]="'./../'+item.routerLink" routerLinkActive="active" (click)="routing(item.routerLink,'',constant.parent,item)" class="nav-link" [ngClass]="{'dropdown-arrow':item.subMenu.length}">{{item.title}}</a>
							<div class="dropdown-menu rounded-0 border-0 m-0" *ngIf="item.subMenu.length">
								<ul>
									<li *ngFor="let itemtwo of item.subMenu">
										<ng-container *ngIf="itemtwo.permission">
										<a (click)="routing(item.routerLink,itemtwo.routerLink,constant.sibling,item)" class="dropdown-item cursor-pointer">{{itemtwo.title}}
											<ng-container *ngIf="countMessage && itemtwo.title === 'Chats'">
												<span class="badge bg-primary">{{countMessage}}</span>
											</ng-container>
										</a>
										</ng-container>
									</li>
								</ul>
							</div>
						</ng-container>
					</li>
				</ul>
			</div>
			<!-- Right Options -->
			<div class="header-right pe-lg-3">
				<ul class="options d-flex align-items-center gap-2">
					<!-- <li  *ngIf="sidebarMenu[sidebarMenu.length-1].siblings[0].permission">
						<a matTooltip="Found an issue? Click to report it." class="font-18 fw-500 rounded-1 text-danger py-2 px-2" href="https://docs.google.com/forms/d/e/1FAIpQLSe9y3qHdSuBRwGtrKNHsQNJ_fqfbMx_TeauyYK01I1cj9llKw/viewform?usp=sf_link" target="_blank">
							<i class="fas fa-bug"></i>
						</a>
					</li> -->
					<li>
						<a matTooltip="Service Report" class="font-18 fw-500 rounded-1 text-danger py-2 px-2" href="https://monitoring-panel.r1.fitelo.net/" target="_blank">
							 <img src="assets/images/icons/report-file.png" width="32">
						</a>
					</li>
					<li class="position-relative d-none d-sm-block">
						<div class="search-wrap dropdown">
							<input type="text" data-bs-toggle="dropdown" #searching [(ngModel)]="filterString" class="input-field bg-white font-12 border-0 shadow-none rounded-1 py-2 px-3 {{sidebarMenu[sidebarMenu.length-1].siblings[1].permission ? '':'d-none'}}" placeholder="Search by ID, Name, Contact...">
							<div class="dropdown-menu py-0 w-100 scroll" infinite-scroll [scrollWindow]="false" (scrolled)="onScroll()" >
								<ng-container *ngIf="filterString.length">
									<ng-container *ngIf="!showSpin && !typing">
										<ul class="emp-listing py-2">
											<li class="cursor-pointer py-2 px-2" *ngFor="let client of memberListData, let x = index">
												<div class="d-flex align-items-center">
													<div class="avatar rounded-circle fw-500" (click)="clientView(client.id)">
														<img *ngIf="client.profileImage" src="../../../../assets/images/icons/avatar.png" class="w-100 h-100 rounded-circle">
														<span *ngIf="!client.profileImage">{{(client.name ?? 'Client').charAt(0) | titlecase}}</span>
													</div>
													<div class="details flex-fill mx-2" (click)="clientView(client.id)">
														<h6 class="font-13 fw-600 mb-1">{{client.name ?? 'Client' | titlecase}}</h6>
														<p class="font-12 text-dark-gray"># {{client.code ?? '000'}}</p>
													</div>
													<div class="action-btn d-flex gap-1 font-14">
														<a href="javascript:void();" class="rounded-circle text-center"><i class="fab fa-rocketchat"></i></a>
														<a (click)="makeCall(client)" class="rounded-circle text-center"><i class="fas fa-phone-alt"></i></a>
													</div>
												</div>
											</li>
											<li *ngIf="lazyLoading">
												<div class="py-2">
													<p class="text-center font-13 fw-500 text-primary py-2 px-2">Loading...</p>
												</div>
											</li>
											<li *ngIf="memberListData.length==0">
												<p class="text-center font-13 fw-500 text-danger py-2 px-2">No data found!</p>
											</li>
										</ul>
									</ng-container>
									<ng-container *ngIf="showSpin || typing">
										<ng-container *ngIf="typing">
											<div class="py-2">
												<p class="text-center font-13 fw-500 py-2 px-2">Typing...</p>
											</div>
										</ng-container>
										<ng-container *ngIf="showSpin && !typing">
											<div class="py-2">
												<p class="text-center font-13 fw-500 text-primary py-2 px-2">Loading...</p>
											</div>
										</ng-container>
									</ng-container>
								</ng-container>
							</div>
						</div>
					</li>
					<!-- <li class="position-relative">
						<a href="javascript:void(0)" (click)="toggleFollowUp()">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256"><path fill="white" d="M221.8 175.94c-5.55-9.56-13.8-36.61-13.8-71.94a80 80 0 1 0-160 0c0 35.34-8.26 62.38-13.81 71.94A16 16 0 0 0 48 200h40.81a40 40 0 0 0 78.38 0H208a16 16 0 0 0 13.8-24.06ZM128 216a24 24 0 0 1-22.62-16h45.24A24 24 0 0 1 128 216Zm-80-32c7.7-13.24 16-43.92 16-80a64 64 0 1 1 128 0c0 36.05 8.28 66.73 16 80Z"/></svg>
							<span class="position-absolute top-0 start-100 translate-middle badge font-10 rounded-pill bg-danger">{{notificationCount}}</span>
						</a>
					</li> -->
					<!-- <li>
						<a href="javascript:void(0)">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48"><g fill="white"><path fill-rule="evenodd" d="M24.8 33h2.7C34.404 33 40 27.404 40 20.5S34.404 8 27.5 8h-7C13.596 8 8 13.596 8 20.5c0 8.06 4.596 12.88 9.43 15.786c2.426 1.458 4.87 2.4 6.716 2.977c.228.071.447.137.654.197V33Zm2 9s-.764-.129-2-.463C19.59 40.125 6 35.05 6 20.5C6 12.492 12.492 6 20.5 6h7C35.508 6 42 12.492 42 20.5S35.508 35 27.5 35h-.7v7Z" clip-rule="evenodd"/><path fill-rule="evenodd" d="M27.691 12.482c1.03.684 1.806 1.793 1.806 3.28c0 1.578-.636 2.738-1.667 3.448a4.523 4.523 0 0 1-1.33.614v1.532a1.5 1.5 0 0 1-3 0v-2.797a1.5 1.5 0 0 1 1.4-1.497c.554-.037.974-.147 1.227-.322a.698.698 0 0 0 .24-.272c.06-.12.13-.334.13-.707c0-.313-.122-.552-.467-.782c-.392-.26-1.022-.452-1.779-.476c-.748-.024-1.475.122-2.01.381c-.536.26-.733.552-.788.767a1.5 1.5 0 1 1-2.906-.746c.345-1.341 1.348-2.217 2.386-2.72c1.04-.505 2.263-.718 3.413-.681c1.143.036 2.363.324 3.345.978Z" clip-rule="evenodd"/><path d="M27 27a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z"/></g></svg>
						</a>
					</li> -->
					<li class="dropdown" *ngIf="sidebarMenu[sidebarMenu.length-1].siblings[2].permission">
						<a href="javascript:void(0)" class="dropdown-arrow d-block" data-bs-toggle="dropdown">
							<span class="user-avatar d-flex align-items-center justify-content-center bg-primary rounded-50" style="width:32px;height:32px;">
								<img *ngIf="profileImage" [src]="profileImage" class="rounded-circle" width="32" alt="../../../assets/images/icons/avatar.png">
								<span *ngIf="!profileImage" class="font-14 fw-600 text-white">{{(userName).charAt(0) | titlecase}}</span>
							</span>
						</a>
						<ul class="dropdown-menu">
							<li><p class="dropdown-item fw-500">{{userDisplayName}}</p></li>
							<li><hr class="dropdown-divider"></li>
							<li><a (click)="myProfile()" class="dropdown-item"><i class="fas fa-user"></i> &nbsp; Profile</a></li>
							<li><a href="javascript:void(0)" class="dropdown-item text-danger" (click)="logout()"><i class="fas fa-sign-out-alt"></i> &nbsp; Logout</a></li>
						</ul>
					</li>
					<li class="navbar-toggler p-0 ms-2" data-bs-toggle="collapse" data-bs-target="#navbarContent">
						<div class="cursor-pointer">
							<img src="../../../../assets/images/icons/menu-icon-white.png" width="28">
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</header>

<div class="AdvancedFilter" [ngClass]="{'active': filterAdvanced}">
	<div class="AdvancedFilter-modal">
		<div class="AdvancedFilter-content">
			<div class="AdvancedFilter-header">
                <p class="fw-bolder">Notifications</p>
                <span class="close" (click)="this.filterAdvanced = !this.filterAdvanced"><i type="button" class="fas fa-times"></i></span>
            </div>
			<div class="AdvancedFilter-body bg-white py-2">
				<app-view-notification ></app-view-notification>
			</div>
		</div>
	</div>
    <div class="closefilter" (click)="this.filterAdvanced = !this.filterAdvanced"></div>
</div>
