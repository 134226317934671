import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RoutePath } from './core/config';

// Routing Layout
import { DASHBOARD_LAYOUT_ROUTE } from './core/config/routes/dashboard-route';
import { SALES_LAYOUT_ROUTE } from './core/config/routes/sales-revenue-route';
import { MANAGE_LAYOUT_ROUTE } from './core/config/routes/manage-users-route';
import { ME_LAYOUT_ROUTE } from './core/config/routes/me-route';
import { ORGANIZATION_LAYOUT_ROUTE } from './core/config/routes/organization-route';
import { MASTER_DATA_LAYOUT_ROUTE } from './core/config/routes/master-data';
import { CONTENTS_LAYOUT_ROUTE } from './core/config/routes/contents-route';

import { LayoutComponent } from './pages/main-layout/layout/layout.component';
import { permissionGuard } from './core/guards/perminsion.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { NotFoundGuard } from './core/guards/not-found.guard';
import { MEDUSA_LAYOUT_ROUTE } from './core/config/routes/medusa-route';

const routes: Routes = [

  {
    path:RoutePath.Empty,
    loadChildren: ()=> import('./pages/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: RoutePath.dashboard,
    component: LayoutComponent,
    children: DASHBOARD_LAYOUT_ROUTE,
    canActivate: [AuthGuard],
    data:{ pageId: ['dashboard'], type:'parent' }
  },
  {
    path: RoutePath.sales_revenue,
    component: LayoutComponent,
    children: SALES_LAYOUT_ROUTE,
    canActivate: [AuthGuard],
    data:{ pageId: ['sales-revenue'], type:['parent'] }
  },
  {
    path: RoutePath.manage_users,
    component: LayoutComponent,
    children: MANAGE_LAYOUT_ROUTE,
    canActivate: [AuthGuard],
    data:{ pageId: ['manage-users'], type:['parent'] }
  },
  {
    path: RoutePath.me,
    component: LayoutComponent,
    children: ME_LAYOUT_ROUTE,
    canActivate: [AuthGuard],
    data:{ pageId: ['me'], type:['parent'] }
  },
  {
    path: RoutePath.organization,
    component: LayoutComponent,
    children: ORGANIZATION_LAYOUT_ROUTE,
    canActivate: [AuthGuard],
    data:{ pageId: ['organization'], type:['parent'] }
  },
  {
    path: RoutePath.master_data,
    component: LayoutComponent,
    children: MASTER_DATA_LAYOUT_ROUTE,
    canActivate: [AuthGuard],
    data:{ pageId: ['master-data'], type:['parent'] }
  },
  {
    path: RoutePath.contents,
    component: LayoutComponent,
    children: CONTENTS_LAYOUT_ROUTE,
    canActivate: [AuthGuard],
    data:{ pageId: ['contents'], type:['parent'] }
  },
  {
    path: RoutePath.medusa,
    component: LayoutComponent,
    children: MEDUSA_LAYOUT_ROUTE,
    canActivate: [AuthGuard],
    data:{ pageId: ['medusa'], type:['parent'] }
  },
  {
    path: RoutePath.unauthorised,
    loadChildren: () => import('./pages/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule)
  },
  {
    path: RoutePath.pageNot_found,
    loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule)
  },
  // {
  //   path: '**',
  //   redirectTo: RoutePath.pageNot_found,
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

