import { environment } from 'src/environments/environment';

export const ApiURL = {
  
  // Common APIs Urls
  otpsend: `${environment.auth_url}/employee/otp/send`,
  VerfiyOtp: `${environment.auth_url}/employee/otp/verify`,
  getList: `${environment.auth_url}/admin/getList`, // Not sure if this API exists
  refreshToken: `${environment.auth_url}/employee/refresh`,
  logout: `${environment.auth_url}/employee/logout`,

  // Diet APIs Urls
  recipieCreate: `${environment.diet_url}/master-data/recipie`,
  createdilydiet: `${environment.diet_url}/master-data/daily-diet-set`,
  dublicatedilydiet: `${environment.diet_url}/master-data/daily-diet-set/duplicate`,
  createWeeklydiet: `${environment.diet_url}/master-data/weekly-diet-set`,
  dublicateWeeklydiet: `${environment.diet_url}/master-data/weekly-diet-set/duplicate`,
  getAllRecipe: `${environment.diet_url}/master-data/recipie/all`,
  getdiet: `${environment.diet_url}/customer/diet`,
  saveDietData: `${environment.diet_url}/customer/diet/bulk`,
  gettimingList: `${environment.diet_url}/customer/diet/timings`,
  setImportWeekly: `${environment.diet_url}/diet/set-import`,
  getDietGallery: `${environment.diet_url}/customer/routine/diet/image`,
  dietEngineImport: `${environment.diet_url}/diet-engine-import`,
  exportDiet:`${environment.diet_url}/customer/diet/export`,
  postProfileData:`${environment.diet_url}/diet-engine-import/recommendation/edit-persona`,
  addDateRange: `${environment.wellness_url}/wellness-group/session/addExtraDays`,
  removeDateRange: `${environment.wellness_url}/wellness-group/session/removeExtraDays`,
  getDateRange: `${environment.wellness_url}/wellness-group/session/extraDays`,
  getLikeDislike: `${environment.diet_url}/master-data/recipie/customer-likes-dislikes`,
  // scoreDiet : `${environment.diet_url}/diet-score-updated/check`,
  // Excercise APIs Urls
  exercises: `${environment.wellness_url}/master-data/Exercise`,
  exercisesAll: `${environment.wellness_url}/master-data/exercise/getAll`,
  weeklyExercises: `${environment.wellness_url}/master-data/weekly-exercise-set`,
  dublicateweeklyExercises: `${environment.wellness_url}/master-data/weekly-exercise-set/duplicate`,
  getweeklyExercises: `${environment.wellness_url}/master-data/weekly-exercise-set/findAll`,
  saveWorkoutBulk: `${environment.wellness_url}/customer/exercise/bulk`,
  getBulkData: `${environment.wellness_url}/customer/exercise`,
  getHistoryExercises: `${environment.wellness_url}/exercise/set-import`,
  exportExercise:`${environment.wellness_url}/customer/exercise/export`,
  upcomingSubs: `${environment.pos_url}/subscription/mark/answered`,


  // Yoga Set APIs Urls
  yoga: `${environment.wellness_url}/master-data/yoga`,
  yogaGetAll: `${environment.wellness_url}/master-data/yoga/getAll`,
  duplicateyoga: `${environment.wellness_url}/master-data/yoga/duplicate`,
  yogaWeeklySave: `${environment.wellness_url}/master-data/weekly-yoga-set`,
  GetyogaWeeklySave: `${environment.wellness_url}/master-data/weekly-yoga-set/findAll`,
  dublicateYogaWeekly: `${environment.wellness_url}/master-data/weekly-yoga-set/duplicate`,
  getBulkYoga: `${environment.wellness_url}/customer/yoga`,
  createBulkYoga: `${environment.wellness_url}/customer/yoga/bulk`,
  getHistoryYoga: `${environment.wellness_url}/Yoga/set-import`,

  // Mind Activity APIs Urls
  mindActivity: `${environment.wellness_url}/master-data/mind-activity`,
  mindActivityAll: `${environment.wellness_url}/master-data/mind-activity/getAll`,
  dublicatemindActivity: `${environment.wellness_url}/master-data/mind-activity/duplicate`,
  saveWeeklymindActivity: `${environment.wellness_url}/master-data/weekly-mind-activity`,
  dublicateWeeklymindActivity: `${environment.wellness_url}/master-data/weekly-mind-activity/duplicate`,
  getBulkActivity: `${environment.wellness_url}/customer/mind-activity/mind-activity`,
  postBulkActivity: `${environment.wellness_url}/customer/mind-activity/bulk`,
  importWeeklyActivity: `${environment.wellness_url}/mind-activity-set-import`,

  // Dashboard APIs Urls
  todayCounselling: `${environment.employee_url}/today-task/counselling`,
  todayFollowups: `${environment.employee_url}/today-task/follow-ups`,
  todayDietpending: `${environment.employee_url}/today-task/diet`,
  todayListing: `${environment.employee_url}/today-task/stats`,
  clientsData:`${environment.employee_url}/today-task/statDetails`,
  counsellingReason:`${environment.employee_url}`,
  todayExercisepending: `${environment.employee_url}/today-task/exercise`,
  todayMindpending: `${environment.employee_url}/today-task/mind`,
  todayTaskCount: `${environment.employee_url}/today-task/dashboard/count`,
  salesTarget: `${environment.payment_url}/cod/dietitian/addMonthlySalesTarget`,
  getSalesData: `${environment.payment_url}/cod/dietitian/getTableData`,
  getCardData: `${environment.payment_url}/cod/dietitian/getCardData`,
  getTargetData: `${environment.payment_url}/cod/dietitian/getTargetHistory`,
  auditDashboard: `${environment.employee_url}/audit`,

  // Homepage APIs Urls
  homeTodayTask: `${environment.employee_url}/today-task`,
  homeCSAT: `${environment.assessment_url}/csat`,
  homeIncentive: `${environment.payment_url}/incentive/dietitian`,
  homeOpportunities: `${environment.pos_url}/opportunities`,
  homeClients: `${environment.employee_url}/today-task`,

   // Weight Stuck Urls
   stuckStats: `${environment.health_url}/weight-log/stuck-stats`,
  weightStuck: `${environment.health_url}/weight-log/weight-stuck`,
  weightGained: `${environment.health_url}/weight-log/weight-gained`,
  weightNotUpdate: `${environment.health_url}/weight-log/weight-not-updated`,
  getComments: `${environment.health_url}/weight-log/wt-details`,
  comments: `${environment.health_url}/weight-log/stuck-comments`,
  weightStuckCount: `${environment.health_url}/weight-log/stuck-counts`,
  getStatusList: `${environment.health_url}/weight-log/stuck-status-list`,
  changeStatus: `${environment.health_url}/weight-log/weight-stuck-action-status`,

  // Contents APIs Urls
  transformation: `${environment.content_url}/transformations`,
  banner: `${environment.content_url}/banner`,
  story: `${environment.content_url}/story`,
  storyCategory: `${environment.content_url}/story/category`,
  posts: `${environment.content_url}/posts`,
  singleViewPosts: `${environment.content_url}/app/explore/posts`,

  // Organization APIs Urls
  employee: `${environment.employee_url}/parent-employee/employee`,
  managerOtpEmployee: `${environment.auth_url}/employee/otp/resend`,
  allEmployees: `${environment.employee_url}/parent-employee/employee/names`,

  // Configuration APIs URL
  getEmployeeDesignationConfiguration: `${environment.employee_url}/parent-employee/employee/designation/names`,
  getEmployeeDepartmentConfiguration: `${environment.employee_url}/parent-employee/employee/department/names`,
  postEmployeeDesignationConfiguration: `${environment.employee_url}/parent-employee/employee/designation`,
  postEmployeeDepartmentConfiguration: `${environment.employee_url}/parent-employee/employee/department`,
  holidayConfiguration: `${environment.employee_url}/roster/holidays`,
  handlingCapacity: `${environment.employee_url}/handling-capacity`,

  // Guidelines APIs Urls
  guidelines: `${environment.health_url}guidelines`, // Not sure if this API exists
  getMedicalCondition: `${environment.health_url}medical-condition`, // Not sure if this API exists
  userGuidelines: `${environment.health_url}user-guidelines`, // Not sure if this API exists
  getMedicalConditionList: `${environment.health_url}/master-data/medical-condition`,
  getAllMedicalConditionList: `${environment.health_url}/master-data/medical-condition/names`,
  userGuidelineImport: `${environment.health_url}user-guidelines/import`, // Not sure if this API exists
  getSingleGuidlines: `${environment.health_url}/master-data/guideline`,
  postUserGuidlines: `${environment.health_url}/customer/guideline`,
  getUserGuidlines: `${environment.health_url}/customer`,

  // Progress APIs Urls
  bodyMeasurment: `${environment.health_url}/body-measurement`,
  weightLog: `${environment.health_url}/weight-log/panel/graph`,
  waterLog: `${environment.health_url}/water-log`,
  smartScale: `${environment.health_url}/smart-scale`,
  bmi: `${environment.health_url}/bmi`,
  weightLogDetails: `${environment.health_url}/weight-log/details`,
  medicalReports: `${environment.health_url}/medical-report`,
  vitalTracker: `${environment.health_url}/vitals/panel`,

  // Timeline APIs Urls
  timeline: `${environment.customer_url}/timeline/fetch`,

  // Health Assessment APIs Urls
  healthAssessment: `${environment.assessment_url}/health-assessment/panel`,

  // CSAT Dash APIs Urls
  csat: `${environment.assessment_url}/csat`,

  // Counselling APIs Urls
  counselling: `${environment.employee_url}/counselling`,
  countCounselling: `${environment.employee_url}/counselling/count`,
  mindCounsellingList:`${environment.assessment_url}/forms-submission`,

  // FollowUp APIs URL
  getAllFollowUpList: `${environment.employee_url}/follow-up`,
  followupCountsList: `${environment.employee_url}/follow-up/count`,

  // RM Notes APIs Urls
  rm_notes: `${environment.customer_url}/rm-notes`,

  // ManageUsers ApIs Urls
  members: `${environment.customer_url}/view/manager-member`,
  assignCoach: `${environment.customer_url}/customer/assign-coach`,
  deleteCoach: `${environment.customer_url}/customer/coach`,
  singleAssignCoach: `${environment.customer_url}/customer/self/assign`,
  bulkAssignCoach: `${environment.customer_url}/customer/bulk/assign`,
  bulkExpirePause: `${environment.pos_url}/subscription/bulkExpirePausedClient`,
  getGoldEmpList:`${environment.employee_url}/parent-employee/employee/names/exclude/goldEmp`,

  // Profile APIs Urls
  profile: `${environment.customer_url}/customer`,
  addCustomerMember: `${environment.customer_url}/customer/add-member`,
  removeCustomerMember: `${environment.customer_url}/customer/remove-member`,
  editEmail: `${environment.customer_url}/customer/editEmail`,
  transferSubscription:`${environment.pos_url}/subscription`,
  wallet: `${environment.payment_url}/wallet/get`,
  postWalletBalance: `${environment.payment_url}/wallet/manualAddition`,

  // Nav-bar ApIs Urls
  memberSearchBar: `${environment.customer_url}/customer/search-bar`,
  customerView: `${environment.customer_url}/view/customer-view`,
  resendOTP: `${environment.auth_url}/customer/otp/resend`,
  userLimit: `${environment.auth_url}/otp/reset/user-limit `,
  resetStore: `${environment.order_url}/admin/custom/fix-cart`,

  //Unavaiability APIs URL
  unavailability: `${environment.employee_url}/parent-employee/employee-unavailability`,
  unavailabilityFilter: `${environment.employee_url}/parent-employee/employee-unavailability/filter`,

  // Survey Forms APIs URL
  getForms: `${environment.assessment_url}/forms`,
  formSubmission: `${environment.assessment_url}/forms-submission`,

  // create order 
  createOrder:`${environment.pos_url}/order`,
  createOrderFree:`${environment.pos_url}/order/free`,
  cancelOrder:`${environment.pos_url}/order/cancel`,
  orderListing:`${environment.pos_url}/order/employee`,
  approvalListing:`${environment.pos_url}/order/get/manager-approved/order`,
  orderListing1:`${environment.order_url}/admin/orders`,
  getCustomerOrderListing:`${environment.pos_url}/order/customer`,
  pendingAmount: `${environment.pos_url}/order/pendingAmount`,
    
  // create link payment 
  onlineCreateLink:`${environment.payment_url}/payment/create-link`,
  offlineCreateLink:`${environment.payment_url}/payment/offline`,
  codCreateLink:`${environment.payment_url}/payment/cod`,

  paymentList:`${environment.payment_url}/payment/list`,
  offlinePayment:`${environment.payment_url}/payment/offline`,
  paymentGateway:`${environment.payment_url}/payment/gateways`,

  showOnAppPayment:`${environment.payment_url}/payment`,
 
  // Notes APIs URL
  notes: `${environment.assessment_url}/notes`,

  // Roster APIs URL
  roster: `${environment.employee_url}/roster`, // Not sure if this API exists
  getRosterList: `${environment.employee_url}/counselling/getList`,
  slotList: `${environment.employee_url}/roster/slots/filter`,
  bookSlot: `${environment.employee_url}/counselling/book`,
  availableEmployees: `${environment.employee_url}/roster/available/employees`,
  rosterReason: `${environment.employee_url}/counselling`,
  doctorsModule: `${environment.employee_url}/counselling-coaches`,
  generateSlots: `${environment.employee_url}/roster/slots/generateAndUpdate`,

  // Wellness APIs URL
  wellness: `${environment.wellness_url}/wellness-group`, 
  wellnessDashboard: `${environment.wellness_url}/wellness-dashboard`,
  wellnessShift: `${environment.wellness_url}/wellness-group/shiftGroup`, 


  // Manage Product
  manageProductCreate: `${environment.pos_url}/product`,
  manageProductGetAll: `${environment.pos_url}/product/all`,
  createCategory: `${environment.pos_url}/product/category`,
  getAllCategory: `${environment.pos_url}/product/categories`,
  getEnumProduct: `${environment.pos_url}/product/enum`,
  getPromise: `${environment.pos_url}/promise`,
  getBundle: `${environment.pos_url}/bundle`,
  downGrade: `${environment.pos_url}/subscription/downgrade`,

  // Manage Discount
  getListing: `${environment.pos_url}/discount`,
  deletePromise: `${environment.pos_url}/discount/plan/promise`,

  // Manage Challenges
  challenges: `${environment.health_url}/challenges`,

  // delivery-address
  addAddress: `${environment.pos_url}/delivery-address`,

  // cod address
  codAddress: `${environment.payment_url}/cod/address/create`,
  codViewAddress: `${environment.payment_url}/cod`,

  // card details
  getSingleCardDetails: `${environment.pos_url}/cart/customer`,
  AddCardItems: `${environment.pos_url}/cart/item`,

  // customer coupon
  getCustomerCoupon: `${environment.pos_url}/coupon/customer`,
  getCouponValidation: `${environment.pos_url}/coupon/validate`,
  getReasonDiscount:`${environment.pos_url}/coupon/discount/reason`,
  getDiscount:`${environment.pos_url}/discount/customer-discount`,
  calculator:`${environment.pos_url}/discount/calculator`,


  // create prommise 
  createPromise:`${environment.pos_url}/promise/create`,
  listPromise:`${environment.pos_url}/promise`,

  // create Customer
  createCustomer: `${environment.customer_url}/customer/create`,
  fetchCustomerPhoneNumber: `${environment.customer_url}/customer/phone`,
  fetchCustomerCode: `${environment.customer_url}/customer/code`,
  fetchCustomerLsq: `${environment.customer_url}/customer/lsq`,
  getSubscriptionDetails: `${environment.pos_url}/subscription/customer`,
  getCustomerPaymentList: `${environment.payment_url}/payment/customer`,
  dietCount: `${environment.diet_url}/customer/diet/count/`,
  pauseReason:`${environment.pos_url}/subscription/panel/pause/reasons`,
  addDays : `${environment.pos_url}/subscription/pause/add`,
  codDelivered : `${environment.payment_url}/cod/delivered`,
  modifyPause: `${environment.pos_url}/subscription`,

  // call Logs APIs URL
  callLogSGet: `${environment.communication_url}/call`,
  recordingUrl: `${environment.communication_url}/call/authenticate-rec`,
  // notification
  notification: `${environment.notifications_url}/push`,

  // manage role APIs URL
  permission: `${environment.auth_url}/permission/module`,
  rolePermission: `${environment.auth_url}/permission/role`,
  uploadModule: `${environment.auth_url}/permission/modules/create`,

  //systemusers
  systemUsers: `${environment.auth_url}/auth-user/list`,
  systemUserStatus: `${environment.auth_url}/auth-user`,
  rolesUser: `${environment.auth_url}/permission/role/name`,

  // customer feedback
  getGraphResponse: `${environment.assessment_url}/csat/survey/graph/response`,
  getGraphRating: `${environment.assessment_url}/csat/survey/graph/rating`,
  getSurveyList: `${environment.assessment_url}/csat/survey`,

  // Subscription all Action
  pauseSubscriptionCount: `${environment.pos_url}/subscription`,
  pauseSubscription: `${environment.pos_url}/subscription/pause`,
  startSubscription: `${environment.pos_url}/subscription/start`,
  unPauseSubscription: `${environment.pos_url}/subscription/unpause`,
  upgradeSubscription: `${environment.pos_url}/subscription/upgrade`,
  activateSubscription: `${environment.pos_url}/subscription/changeSubscriptionState`,
  updateEndDate: `${environment.pos_url}/subscription/changeEndDate`,
  updateStartDate: `${environment.pos_url}/subscription/changeStartDate`,

  //referral
  referral: `${environment.customer_url}/refer-earn/getReferalButton`,
  referralPostApi: `${environment.customer_url}/refer-earn/createEmployeeReferral`,
  referralSalesPitching: `${environment.customer_url}/refer-earn/UpdateSalesPitch`,
  referralSupport: `${environment.customer_url}/refer-earn/sendSupportEmails`,
  referralCount: `${environment.customer_url}/refer-earn`,
  referralHistory: `${environment.customer_url}/refer-earn`,

  //reportees
  allReportees: `${environment.employee_url}/parent-employee/employee`,

  // Bulk Shift Client
  bulkShuffleClient: `${environment.employee_url}/roster/bulk-shuffle-client`,
  activeAdmin: `${environment.employee_url}/parent-employee/employee/available/roster`,
  bulkShuffleClientAssign: `${environment.employee_url}/roster/bulk-shuffle-client/assign`,
  availableAdmin: `${environment.employee_url}/parent-employee/employee/available/roster`,
  employees: `${environment.employee_url}/parent-employee/employee/names/rosterEnabled`,

  // stream chat
  getToken: `${environment.communication_url}/chat/employee/token/generate`,
  addMember: `${environment.communication_url}/chat/addMemberByAdmin`,
  createLabels: `${environment.communication_url}/chat/create-label`,
  getLabels: `${environment.communication_url}/chat/label `,
  assignLabels: `${environment.communication_url}/chat/add-label-group`,
  getLabelChannels: `${environment.communication_url}/chat/group-label`,
  broadcast: `${environment.communication_url}/chat/chat-broadcast`,
  editLabel: `${environment.communication_url}/chat/update-label`,
  deleteLabel: `${environment.communication_url}/chat/delete-label`,
  getBroadcastList: `${environment.communication_url}/chat/broadcast-list`,
  updateBroadcast: `${environment.communication_url}/chat/update-broadcast`,
  deleteBroadcast: `${environment.communication_url}/chat/delete-broadacast`,
  chatTemplateMessage: `${environment.communication_url}/chat/template`,
  activeUser: `${environment.communication_url}/chat/filter`,

  // create Channel 
  getChannelListing: `${environment.communication_url}/chat/channel/get`,  
  createChannelMaster: `${environment.communication_url}/chat/channel/create`,  
  masterChannelFetch: `${environment.communication_url}/chat/customer/fetch`,  
  masterChannelSendMessage: `${environment.communication_url}/chat/message/send`,  
  masterChannelStatus: `${environment.communication_url}/chat/channel/update`,  

  // activeUser: `${environment.communication_url}/chat/filter?label=`,
  unreadCount: `${environment.communication_url}/chat/unread`,
  broadcastHistory: `${environment.communication_url}/chat/broadcast/history`,
  broadcastDelete: `${environment.communication_url}/chat/broadcast/stop`,
  broadcastUpdate: `${environment.communication_url}/chat/broadcast/update`,
  makeBroadcast: `${environment.communication_url}/chat/make-broadcast`,
  
  // Bulk upload manual
  bulkUpload: `${environment.employee_url}/counselling/manual-bulk-upload`,
  bulkAssign: `${environment.employee_url}/counselling/manual-bulk-upload/assign`,

  //merge contact
  mergeNumber: `${environment.customer_url}/customer/merge`,

   // Opportunities
   earlyRenewal: `${environment.customer_url}/view/early-renewal-opportunities`,
   stats: `${environment.pos_url}/opportunities/stats`,
   extension: `${environment.customer_url}/view/extension-opportunities`,
   renewal: `${environment.customer_url}/view/renewal-opportunities`,
   converted: `${environment.customer_url}/view/converted-opportunities`,
   statusTypes: `${environment.pos_url}/opportunities/status-types`,
   setStatus: `${environment.pos_url}/opportunities`,
   referalOpportunities: `${environment.customer_url}/refer-earn/getCustomerPersonaliseData`,
   modifyClient: `${environment.customer_url}/refer-earn/happyClientChange`,

  //  logistics
  logistics: `${environment.pos_url}/order/logistics`,
  bulkUploadLogistics: `${environment.pos_url}/order/bulk`,
  addAddressLogistics: `${environment.pos_url}/order/order/address/medusa`,
  orderPending: `${environment.pos_url}/order/pending`,

  //  Manage Appointment lab test
  labTest:`${environment.health_url}/lab-test`,
  allAddresses: `${environment.customer_url}/customer/multiple/addresses`,
  addLabAddress: `${environment.customer_url}/customer/address`,
  downloadReport: `${environment.health_url}/lab-test/report`,

  // form builder api
  formListing: `${environment.assessment_url}/forms/get-list`,
  updateForm: `${environment.assessment_url}/forms`,

  // e-Commerce cart
  fetchCarts: `${environment.pos_url}/order/MedusaCarts`,
  publishCart: `${environment.pos_url}/order/publishCart`,
  checkCart: `${environment.pos_url}/order/cart/check`,
  storeDisease: `${environment.order_url}/store/product-disease`,
  
  // referral mapping api
  getReferralMapping: `${environment.employee_url}/parent-employee/employee/salespartnerMap`,
  getSalesPerson: `${environment.employee_url}/parent-employee/employee/salesPersons`,
  mapSalesPerson: `${environment.employee_url}/parent-employee/employee/createSalespartnerMap`,
  getToggleData:`${environment.customer_url}/refer-earn/mappingSwitch`,

}

