<div class="admin-wrapper">
  <!-- Main Header -->
  <app-navbar [countMessage]="messageCount"></app-navbar>
  <main class="setup-guide-ui d-flex align-items-stretch" [ngStyle]="{'margin-top': navHeight}">
    <!-- Nav Sidebar -->
    <div class="nav-sidebar scrollbar-hidden" [ngClass]="{'addClass':classNavBar}" (mouseout)="classNavBar = false" [ngStyle]="{'height': navbarHeight, 'top': navHeight}">
      <ul class="sidebar-menus mt-5">
        <li class="nav-item" *ngFor="let item of siblingMenu">
          <ng-container *ngIf="item.permission">
            <a (click)="changedrouter(item.routerLink)" [ngClass]="{'active':lastURl === item.routerLink}" class="nav-link cursor-pointer">
              <span class="icon"><img [src]="item.icon" width="18" height="18"></span>
              <p class="name">{{item.title}}</p>
              <ng-container *ngIf="messageCount && item.title === 'Chats'">
                <span class="badge bg-primary">{{messageCount}}</span>
              </ng-container>
            </a>
          </ng-container>
        </li>
      </ul>
    </div>
    <!-- Main Content -->
    <div class="main-content" [ngStyle]="{'min-height': navbarHeight}">
        <router-outlet></router-outlet>
    </div>
  </main>
</div>
