import { Routes } from '@angular/router';
import { RoutePath } from '../router.config';
import { AuthGuard } from '../../guards/auth.guard';

export const SALES_LAYOUT_ROUTE: Routes = [
  {
    path: RoutePath.customers,
    canActivate: [AuthGuard],
    data:{ pageId: ['customers'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/sales-revenue/customers/customers.module').then((m) => m.CustomersModule),
  },
  {
    path: RoutePath.cart,
    canActivate: [AuthGuard],
    data:{ pageId: ['cart'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/sales-revenue/cart/cart.module').then((m) => m.CartModule),
  },
  {
    path: RoutePath.orders,
    canActivate: [AuthGuard],
    data:{ pageId: ['orders'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/sales-revenue/orders/orders.module').then((m) => m.OrdersModule),
  },
  {
    path: RoutePath.payments,
    canActivate: [AuthGuard],
    data:{ pageId: ['payments'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/sales-revenue/payments/payments.module').then((m) => m.PaymentsModule),
  },
  {
    path: RoutePath.customer_view,
    canActivate: [AuthGuard],
    data:{ pageId: ['customer-view'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/sales-revenue/customer-view/customer-view.module').then((m) => m.CustomerViewModule),
  },
  {
    path: RoutePath.store,
    canActivate: [AuthGuard],
    data:{ pageId: ['store'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/sales-revenue/store/store.module').then((m) => m.StoreModule),
  },
  {
    path: RoutePath.approvals,
    canActivate: [AuthGuard],
    data:{ pageId: ['approvals'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/sales-revenue/approvals/approvals.module').then((m) => m.ApprovalsModule),
  },
  {
    path: RoutePath.calculator,
    canActivate: [AuthGuard],
    data:{ pageId: ['calculator'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/sales-revenue/calculator/calculator.module').then((m) => m.CalculatorModule),
  },
    {
    path: RoutePath.employee,
    canActivate: [AuthGuard],
    data:{ pageId: ['employee'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/sales-revenue/employee-otp/employee-otp.module').then((m) => m.EmployeeOtpModule),
  },
  {
    path: RoutePath.logistics,
    canActivate: [AuthGuard],
    data:{ pageId: ['logistics'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/sales-revenue/logistics/logistics.module').then((m) => m.LogisticsModule),
  },

]
