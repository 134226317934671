import { Injectable } from '@angular/core';
import { ApiURL } from './api';
import { HttpService } from './http.service';
import { LocalstorageService } from './localstorage.service';
import { login, verfiyotp } from '../shared/typings/app.typings';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpService:HttpService,private _localstorageService: LocalstorageService,) { }
  TOKEN_KEY = 'auth_token'; // declare variable

  // inject the services in constructor for api call
  otpSend(object:login){
    return this.httpService.postAll(ApiURL.otpsend, object)
  }

  // for varify otp and we paas an object in body , url
  verfiyOtp(body:verfiyotp){
    return this.httpService.postAll(ApiURL.VerfiyOtp, body)
  }

  // refresh token api call and passing the refreshtoken , token
  refreshToken(token: string) {
    return this.httpService.postAll(ApiURL.refreshToken, {refreshToken:token})
  }

  loggedIn() {
    return !!this._localstorageService.getItem('Token')
  }

  // get all employee list data
  getEmployeeData(){
    return this.httpService.getAll(ApiURL.allEmployees)
  }

  // log out
  logout(){
    return this.httpService.patchOnlyUrlApi(ApiURL.logout)
  }

    // get all employee list data
    getReporteesData(payload: {employeeIds:Array<string>, flat:boolean}){
      const reporteeUrl = `${ApiURL.allReportees}/v2/all-reportees`;
      return this.httpService.postAll(reporteeUrl, payload)
    }
   
    getReporteesDataV2(payload: {employeeIds:Array<string>, flat:boolean}){
      const reporteeUrl = `${ApiURL.allReportees}/v2/all-reportees`;
      return this.httpService.postAll(reporteeUrl, payload)
    }
}
